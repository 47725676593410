<template>
  <div>
    <div class="row users-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.USERS')">
          <template v-slot:toolbar>
            <b-button
              v-if="canAdd"
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              v-if="canRemove"
              variant="danger"
              class="mr-4"
              v-b-modal.confirm-1
              :disabled="userTable.selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
            <download-csv :data="userTable.users">
              <b-button variant="success">
                {{ $t("USERS.EXPORT_RESULTS") }}
              </b-button>
            </download-csv>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="userTable.search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                  @input="searchUser()"
                ></v-text-field>
              </div>
            </div>
            <div class="col-sm-8">
              <b-alert show variant="secondary"
                >{{ $t("USERS.PERM.DISABLED_INFO") }}
              </b-alert>
            </div>
            <v-data-table
              v-model="userTable.selected"
              :headers="headers"
              :items="userTable.users"
              :single-select="userTable.singleSelect"
              :items-per-page="userTable.itemPerPage"
              :server-items-length="userTable.total"
              :options.sync="userTable.pagination"
              @update:items-per-page="changePerPage"
              @update:page="getCategoriesByPagination"
              @update:sort-desc="getCategoriesBySort"
              show-select
              item-key="id"
              class="elevation-1"
              ref="userTable"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 25, 50, -1],
              }"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="userTable.nodata">
                  <tr>
                    <td colspan="7" class="text-center">
                      {{ $t("COMMON.NO_DATA") }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <template v-if="!isMobile">
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :class="{ unactiveRow: item.disabled == 1 }"
                    >
                      <td>
                        <v-checkbox
                          v-model="userTable.selected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          class="cusTable-checkbox"
                          color="rgba(0,0,0,.54)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-start">{{ item.username }}</td>
                      <td class="text-start">{{ item.domain }}</td>
                      <td class="text-start" v-if="item.account_type == 1">
                        <v-chip color="blue" class="text-white">{{
                          $t("USERS.BASIC")
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else-if="item.account_type == 2">
                        <v-chip color="orange" class="text-white">{{
                          $t("USERS.ENHANCED")
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else-if="item.account_type == 6">
                        <v-chip color="green" class="text-white">{{
                          $t("USERS.EXCHANGE")
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else-if="item.account_type == 9">
                        <v-chip color="red" class="text-white">{{
                          $t("USERS.OFFICE365")
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else-if="item.account_type == 4">
                        <v-chip color="yellow" class="text-white">{{
                          $t("USERS.AUDIT_ACCOUNT")
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else-if="item.account_type == 3">
                        <v-chip color="gray" class="">{{
                          $t("USERS.OEX") + ": "
                          + $t("USERS.NORMAL")
                        }}</v-chip>
                        <v-chip color="cyan" class="text-white">{{
                          mailboxSubtypeData.find(o => o.value == item.oex_subtype).name
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else-if="item.account_type == 5">
                        <v-chip color="gray" class="">{{
                          $t("USERS.OEX") + ": "
                          + $t("USERS.SHARED") 
                        }}</v-chip>
                        <v-chip color="cyan" class="text-white">{{
                          mailboxSubtypeData.find(o => o.value == item.oex_subtype).name
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-else-if="item.account_type == 7">
                        <v-chip color="gray" class="">{{
                          $t("PUBLIC_FOLDERS.PUBLIC_FOLDER") 
                        }}</v-chip>
                      </td>
                      <td class="text-start" v-if="!item.lastauth_timestamp">
                        {{ $t("USERS.NEVER") }}
                      </td>
                      <td class="text-start" v-else>
                        {{ item.lastauth_timestamp }}
                      </td>
                      <td class="text-start">
                        <b-progress
                          v-if="
                            accountsWithOptions.includes(item.account_type)
                          "
                          :value="
                            (item.quota * item.usage.replace('%', '')) / 100
                          "
                          :max="item.quota"
                          :variant="pBarColor(item.usage)"
                          class="quota-pBar"
                        ></b-progress>
                        <p
                          class="quota-pBardec"
                          v-if="
                            accountsWithOptions.includes(item.account_type)
                          "
                        >
                          {{
                            $t("USERS.USER_QUOTA", {
                              num1: progressNum(item.quota, item.usage),
                              num2: item.quota / 1000,
                            })
                          }}
                          <!-- {{ progressNum(item.quota, item.usage) }}GB free of
                        {{ item.quota / 1000 }}GB -->
                        </p>
                      </td>
                      <td class="text-start">
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="createOtPass(item)"
                          v-b-tooltip.hover
                          :title="$t('COMMON.OTPASSWORD')"
                          v-if="
                            accountsWithOptions.includes(item.account_type) &&
                            canImpersonate && 
                            !accountsOEX.includes(item.account_type)
                          "
                        >
                          vpn_key
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="showItemInfor(item)"
                          v-b-tooltip.hover
                          :title="$t('COMMON.EDIT')"
                          v-if="
                            accountsWithOptions.includes(item.account_type) &&
                            canAdd
                          "
                        >
                          edit
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="showItemPermissions(item)"
                          v-b-tooltip.hover
                          :title="$t('USERS.PERM.PERMISSIONS')"
                          v-if="
                            accountsWithOptions.includes(item.account_type) &&
                            !accountsOEX.includes(item.account_type) &&
                            canAdd
                          "
                        >
                          mdi-account-check
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="showOexUserInfo(item, 'permissions')"
                          v-b-tooltip.hover
                          :title="$t('USERS.PERM.PERMISSIONS')"
                          v-if="
                            accountsOEX.includes(item.account_type) &&
                            item.account_type != 7 &&
                            canAdd
                          "
                        >
                          mdi-account-check
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="showOexUserInfo(item, 'forwards')"
                          v-b-tooltip.hover
                          :title="$t('USERS.PERM.FORWARDS')"
                          v-if="
                            accountsOEX.includes(item.account_type) &&
                            item.account_type != 7 &&
                            canAdd
                          "
                        >
                          mdi-fast-forward
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="saveDeltem(item)"
                          v-b-modal.confirm-2
                          v-b-tooltip.hover
                          :title="$t('COMMON.DELETE')"
                          v-if="item.account_type != 7 && canRemove"
                        >
                          delete
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          class="mr-1"
                          @click="goNewAdmin(item)"
                          v-b-tooltip.hover
                          :title="$t('USERS.USER_CONTROL_PANEL')"
                          v-if="
                            accountsWithOptions.includes(item.account_type) &&
                            canImpersonate &&
                            !accountsOEX.includes(item.account_type)
                          "
                        >
                          input
                        </v-icon>
                        <v-icon
                          medium
                          color="dark"
                          @click="getOTPassAndToken(item)"
                          v-b-tooltip.hover
                          :title="$t('DOMAINS.WEBMAIL')"
                          v-if="
                            accountsWithOptions.includes(item.account_type) &&
                            canImpersonate &&
                            !accountsOEX.includes(item.account_type)
                          "
                        >
                          mail
                        </v-icon>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      :class="{ unactiveRow: item.disabled == 1 }"
                    >
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header"></div>
                        <v-checkbox
                          v-model="userTable.selected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          class="cusTable-checkbox"
                          color="rgba(0,0,0,.54)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.USERNAME") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          {{ item.username }}
                        </div>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.DOMAIN") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          {{ item.domain }}
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-if="item.account_type == 1"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="blue" class="text-white">{{
                            $t("USERS.BASIC")
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-else-if="item.account_type == 2"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="orange" class="text-white">{{
                            $t("USERS.ENHANCED")
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-else-if="item.account_type == 6"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="green" class="text-white">{{
                            $t("USERS.EXCHANGE")
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-else-if="item.account_type == 9"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="red" class="text-white">{{
                            $t("USERS.OFFICE365")
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-else-if="item.account_type == 4"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="green" class="text-white">{{
                            $t("USERS.AUDIT_ACCOUNT")
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-else-if="item.account_type == 3"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="gray" class="">{{
                            $t("USERS.OEX") + ": "
                            + $t("USERS.NORMAL")
                          }}</v-chip>
                          <v-chip color="cyan" class="text-white">{{
                            mailboxSubtypeData.find(o => o.value == item.oex_subtype).name
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-else-if="item.account_type == 5"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="gray" class="">{{
                            $t("USERS.OEX") + ": "
                            + $t("USERS.SHARED") 
                          }}</v-chip>
                          <v-chip color="cyan" class="text-white">{{
                            mailboxSubtypeData.find(o => o.value == item.oex_subtype).name
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-else-if="item.account_type == 7"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.TYPE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-chip color="gray" class="">{{
                            $t("PUBLIC_FOLDERS.PUBLIC_FOLDER") 
                          }}</v-chip>
                        </div>
                      </td>
                      <td
                        class="v-data-table__mobile-row"
                        v-if="!item.lastauth_timestamp"
                      >
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("USERS.LAST_LOGIN") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          {{ $t("USERS.NEVER") }}
                        </div>
                      </td>
                      <td class="v-data-table__mobile-row" v-else>
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("USERS.LAST_LOGIN") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          {{ item.lastauth_timestamp }}
                        </div>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("USERS.QUSAGE") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <b-progress
                            :value="
                              (item.quota * item.usage.replace('%', '')) / 100
                            "
                            :max="item.quota"
                            :variant="pBarColor(item.usage)"
                            class="quota-pBar"
                            v-if="
                              accountsWithOptions.includes(item.account_type)
                            "
                          ></b-progress>
                          <p
                            class="quota-pBardec"
                            v-if="
                              accountsWithOptions.includes(item.account_type)
                            "
                          >
                            {{
                              $t("USERS.USER_QUOTA", {
                                num1: progressNum(item.quota, item.usage),
                                num2: item.quota / 1000,
                              })
                            }}
                          </p>
                        </div>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.ACTION") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <v-icon
                            medium
                            color="dark"
                            class="mr-1"
                            @click="createOtPass(item)"
                            v-b-tooltip.hover
                            :title="$t('COMMON.OTPASSWORD')"
                            v-if="
                              accountsWithOptions.includes(item.account_type) &&
                              canImpersonate && 
                              !accountsOEX.includes(item.account_type)
                            "
                          >
                            vpn_key
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            class="mr-1"
                            @click="showItemInfor(item)"
                            v-b-tooltip.hover
                            :title="$t('COMMON.EDIT')"
                            v-if="
                              accountsWithOptions.includes(item.account_type) &&
                              canAdd
                            "
                          >
                            edit
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            class="mr-1"
                            @click="showItemPermissions(item)"
                            v-b-tooltip.hover
                            :title="$t('USERS.PERM.PERMISSIONS')"
                            v-if="
                              accountsWithOptions.includes(item.account_type) &&
                              canAdd
                            "
                          >
                            mdi-account-check
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            class="mr-1"
                            @click="saveDeltem(item)"
                            v-b-modal.confirm-2
                            v-b-tooltip.hover
                            :title="$t('COMMON.DELETE')"
                            v-if="item.account_type != 7 && canRemove"
                          >
                            delete
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            class="mr-1"
                            @click="goNewAdmin(item)"
                            v-b-tooltip.hover
                            :title="$t('USERS.USER_CONTROL_PANEL')"
                            v-if="
                              accountsWithOptions.includes(item.account_type) &&
                              canImpersonate && 
                              !accountsOEX.includes(item.account_type)
                              "
                          >
                            input
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            @click="getOTPassAndToken(item)"
                            v-b-tooltip.hover
                            :title="$t('DOMAINS.WEBMAIL')"
                            v-if="
                              accountsWithOptions.includes(item.account_type) &&
                              canImpersonate &&
                              !accountsOEX.includes(item.account_type)
                            "
                          >
                            mail
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-data-table>
            <!-- ----------add user modal------------- -->
            <b-modal id="addUser" ref="addUser" :title="CEmodalTitle" size="lg">
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("USERS.ACCOUNT_TYPE") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="updateform.accountType.selected"
                      value-field="value"
                      text-field="name"
                      :options="updateform.accountType.data"
                      @change="changeAccountType"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="updateform.accountType.selected == 'OEX'">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("USERS.ACCOUNT_TYPE_OEX") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="updateform.accountTypeOEX"
                      value-field="value"
                      text-field="name"
                      :options="accountTypeDataForOEXdomain"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="updateform.accountType.selected == 'OEX'">
                  <b-col sm="4">
                    <label class="mb-0">{{ $t("USERS.MAILBOX_SUBTYPE") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="updateform.mailboxSubtype.selected"
                      value-field="value"
                      text-field="name"
                      :options="mailboxSubtypeData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-1" v-if="isCreateModal">
                  <b-col sm="4">
                    <label
                      >{{ $t("COMMON.USER_NAME") }} @
                      {{ $t("COMMON.DOMAIN") }}</label
                    >
                  </b-col>
                  <b-col sm="8">
                    <b-row>
                      <b-col sm="4" class="groupFormUser">
                        <b-form-input
                          v-model="$v.updateform.pageUserName.$model"
                          :state="validateState('pageUserName')"
                          :placeholder="$t('COMMON.USER_NAME')"
                          @keydown.space.prevent
                          aria-describedby="input-pageusername-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="input-pageusername-feedback"
                        >
                          {{ $t("VALIDATION.USERNAME_FIELD") }}
                        </b-form-invalid-feedback>
                      </b-col>
                      <b-col sm="8" class="groupFormDomain">
                        <b-input-group prepend="@">
                          <b-form-select
                            v-model="updateform.domain.selected"
                            value-field="domain."
                            text-field="domain"
                            :options="updateform.domain.data"
                            disabled
                          ></b-form-select>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <div v-show="isAccountWithOptions">
                  <b-row class="my-4" v-if="updateform.accountType.selected != 'OEX' || updateform.accountTypeOEX !== 5">
                    <b-col sm="4">
                      <label for="password" class="mb-0">{{
                        $t("COMMON.PASSWORD")
                      }}</label>
                      <p class="user-password-edit">
                        <span @click="newPassword()">{{
                          $t("COMMON.NEW")
                        }}</span
                        >&nbsp;|&nbsp;
                        <span @click="cancelPassword()">{{
                          $t("COMMON.CANCEL")
                        }}</span
                        >&nbsp;|&nbsp;
                        <span @click="copyPassword('password')">{{
                          $t("COMMON.COPY")
                        }}</span>
                      </p>
                      <!-- <p class="user-pass-dec">
                        {{ $t("USERS.PASS_DEC") }}
                      </p> -->
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        id="password"
                        ref="password"
                        v-model="updateform.password"
                        :placeholder="$t('COMMON.PASSWORD')"
                        @input="checkPassword"
                        @focus="isShowPassDesc = true"
                        :state="valid_password"
                      ></b-form-input>
                      <ul class="ml-5 my-2" v-show="isShowPassDesc">
                        <li
                          v-bind:class="{
                            is_validPass: contains_eight_characters,
                          }"
                        >
                          {{ $t("VALIDATION.MIN_LETTERS", { num: 8 }) }}
                        </li>
                        <li v-bind:class="{ is_validPass: contains_lowercase }">
                          {{ $t("VALIDATION.LOWERCASE_LETTER") }}
                        </li>
                        <li v-bind:class="{ is_validPass: contains_uppercase }">
                          {{ $t("VALIDATION.UPPERCASE_LETTER") }}
                        </li>
                        <li v-bind:class="{ is_validPass: contains_number }">
                          {{ $t("VALIDATION.NUMBER_LETTER") }}
                        </li>
                        <li
                          v-bind:class="{
                            is_validPass: contains_special_character,
                          }"
                        >
                          {{ $t("VALIDATION.SPECIAL_LETTER") }}
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="4">
                      <label for="nameOfUser">{{
                        $t("USERS.NAME_OF_USER")
                      }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        id="nameOfUser"
                        v-model="$v.updateform.nameOfUser.$model"
                        :state="validateState('nameOfUser')"
                        :placeholder="$t('USERS.NAME_OF_USER')"
                        aria-describedby="input-nameOfUser-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-nameOfUser-feedback">
                        {{ $t("VALIDATION.REQUIRED_FIELD") }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                  <b-row class="my-4">
                    <b-col sm="4">
                      <label for="quota">{{ $t("COMMON.QUOTA") }}</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                        id="quota"
                        v-model="$v.updateform.quota.$model"
                        :state="validateState('quota')"
                        type="number"
                        :placeholder="$t('COMMON.QUOTA')"
                        aria-describedby="input-quota-feedback"
                        step="0.1"
                        min="0.1"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.updateform.quota.required"
                      >
                        {{ $t("VALIDATION.REQUIRED_FIELD") }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-if="!$v.updateform.quota.quotaZeroValidator"
                      >
                        {{ $t("VALIDATION.QUOTA_ZERO") }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback id="input-quota-feedback">
                        {{
                          $t("VALIDATION.QUOTA_FIELD", { num: maxQuotaValue() })
                        }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                  <b-button
                    variant="link"
                    v-if="!showMore"
                    @click="showMore = true"
                    class="float-right"
                    >{{ $t("USERS.MORE_OPTIONS") }}</b-button
                  >
                  <div v-show="showMore">
                    <b-row class="my-4">
                      <b-col sm="4">
                        <label class="mb-0">{{ $t("COMMON.LANGUAGE") }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.language.selected"
                          value-field="value"
                          text-field="name"
                          :options="updateform.language.data"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4" v-if="!isCreateModal && updateform.accountType.selected != 'OEX'">
                      <b-col sm="4">
                        <label class="mb-0">{{ $t("COMMON.DISABLED") }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.disabled.selected"
                          value-field="value"
                          text-field="name"
                          :options="disabledData"
                          :disabled="canEnable == false"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4" v-if="updateform.accountType.selected != 'OEX'">
                      <b-col sm="4">
                        <label class="mb-0">{{
                          $t("USERS.FACTOR_AUTH")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.tfactorAuth.selected"
                          value-field="value"
                          text-field="name"
                          :options="tfactorAuthData"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4" v-if="updateform.accountType.selected != 'OEX'">
                      <b-col sm="4">
                        <label class="mb-0">{{
                          $t("COMMON.LOCALIZATION")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.timeZone.selected"
                          value-field="timezone"
                          text-field="timezone"
                          :options="updateform.timeZone.data"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4" v-if="updateform.accountType.selected != 'OEX'">
                      <b-col sm="4">
                        <label class="mb-0">{{
                          $t("COMMON.DATE_FORMAT")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.dateFormat.selected"
                          value-field="value"
                          text-field="value"
                          :options="updateform.dateFormat.data"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4" v-if="!isCreateModal && updateform.accountType.selected != 'OEX'">
                      <b-col sm="4">
                        <label class="mb-0">{{
                          $t("COMMON.HOME_COUNTRY")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <model-list-select :list="updateform.homeCountry.data"
                          v-model="updateform.homeCountry.selected"
                          option-value="value"
                          option-text="text">
                        </model-list-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4" v-if="!isCreateModal && updateform.accountType.selected != 'OEX'">
                      <b-col sm="4">
                        <label class="mb-0">{{
                          $t("COMMON.TRAVEL_MODE")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.travelMode"
                          :options="travelModeData"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4" v-if="!isCreateModal && updateform.accountType.selected != 'OEX'">
                      <b-col sm="4">
                        <label class="mb-0">{{
                          $t("USERS.ONLY_LOCAL_SMTP")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.onlylocal_smtp.selected"
                          value-field="value"
                          text-field="name"
                          :options="onlylocal_smtpData"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    variant="link"
                    v-if="showMore"
                    @click="showMore = false"
                    class="float-right"
                    >{{ $t("USERS.LESS_OPTIONS") }}</b-button
                  >
                </div>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-if="isCreateModal"
                  v-on:click="createItem()"
                >
                  {{ $t("USERS.ADD_USER") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-else
                  v-on:click="updateItemInfor()"
                >
                  {{ $t("USERS.UPDATE_USER") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------add user modal end------------- -->

            <!-- ----------permissions modal------------- -->
            <b-modal
              id="userPermissions"
              ref="userPermissions"
              :title="permissionForTitle"
              size="lg"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="6">
                    <b-row class="my-4">
                      <h4 class="mb-0">{{ $t("USERS.PERM.CAN_ACCESS") }}</h4>
                    </b-row>
                    <b-row class="my-4">
                      <b-form-checkbox-group
                        v-model="permissionsForm.enabledPermissions"
                        :options="permissions"
                        stacked
                        size="lg"
                        name="permissions"
                      ></b-form-checkbox-group>
                    </b-row>
                  </b-col>
                  <b-col sm="6">
                    <b-row class="my-4">
                      <h4 class="mb-0">{{ $t("USERS.PERM.SERVICE_PERM") }}</h4>
                    </b-row>
                    <b-row class="my-4">
                      <b-form-checkbox-group
                        v-model="permissionsForm.enabledServicePermissions"
                        :options="servicePermissions"
                        stacked
                        size="lg"
                        name="permissions"
                      ></b-form-checkbox-group>
                    </b-row>
                  </b-col>
                </b-row>
                <b-button variant="link" @click="showHourPermissions()">
                  {{ $t("USERS.PERM.ACCESS_HOURS") }}
                </b-button>
                <b-button variant="link" @click="showSendAsPermissions()">
                  {{ $t("USERS.PERM.SEND_AS") }}
                </b-button>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('userPermissions')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateUserPermissions()"
                >
                  {{ $t("USERS.PERM.UPDATE_PERM") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------end permissions modal------------- -->

            <!-- ----------hours permissions modal------------- -->
            <b-modal
              id="hourPermissions"
              ref="hourPermissions"
              :title="permissionForTitle"
              size="m"
            >
              <b-container fluid>
                <b-form-checkbox
                  v-model="showTimeRestrictions"
                  name="check-button"
                  switch
                  size="lg"
                >
                  <h4 class="mb-5">{{ $t("USERS.PERM.ACCESS_HOURS") }}</h4>
                </b-form-checkbox>
                <!-- days of the week schedule -->
                <div v-if="showTimeRestrictions">
                  <div
                    v-for="(daySch, index) in permissionsForm.loginSchedule"
                    :key="index"
                    class="my-0"
                  >
                    <b-form-checkbox
                      v-model="daySch.allowed"
                      name="check-button"
                      switch
                      size="lg"
                    >
                      <b>{{ $t("COMMON.DAYS_ARRAY." + daySch.day) }}</b>
                    </b-form-checkbox>

                    <b-row class="mb-1" v-if="daySch.allowed">
                      <b-col sm="6">
                        <b-form-timepicker
                          :placeholder="$t('USERS.PERM.UNRESTRICTED')"
                          v-model="daySch.loginStartHour"
                          v-bind="labelsHours"
                          reset-button
                          :locale="storeLanguage"
                          hour-cycle="h23"
                          :hour12="false"
                          :minutes-step="10"
                        ></b-form-timepicker>
                      </b-col>
                      <b-col sm="6">
                        <b-form-timepicker
                          :placeholder="$t('USERS.PERM.UNRESTRICTED')"
                          v-model="daySch.loginEndHour"
                          v-bind="labelsHours"
                          reset-button
                          :locale="storeLanguage"
                          hour-cycle="h23"
                          :hour12="false"
                          :minutes-step="10"
                        ></b-form-timepicker>
                      </b-col>
                    </b-row>
                    <b-form-group
                      content-cols="10"
                      class="mb-1"
                      :invalid-feedback="invalidHoursFeedback(daySch)"
                      :state="stateHours(daySch)"
                    >
                    </b-form-group>
                  </div>
                </div>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('hourPermissions')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateHourPermissions()"
                >
                  {{ $t("USERS.PERM.UPDATE_HOURS") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------end hour permissions modal------------- -->

            <!-- ----------send as permissions modal------------- -->
            <b-modal
              hide-footer
              id="sendasPermissions"
              ref="sendasPermissions"
              size="lg"
              :title="sendasTitle"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="5">
                    <b-form-select
                      v-model="permissionsForm.domainUsers.selected"
                      value-field="value"
                      text-field="text"
                      :options="permissionsForm.domainUsers.data"
                    ></b-form-select>
                  </b-col>
                  <b-col sm="4">
                    <b-button variant="primary" @click="addSendAsPermissions()">
                      {{ $t("LISTS.ADD_MEMBER") }}
                    </b-button>
                  </b-col>
                  <!--<b-col sm="3">
                    <b-button
                      variant="danger"
                      v-b-modal.delMembersConfirm-modal
                      :disabled="canSendAsTable.selected.length == 0"
                    >
                      {{ $t("COMMON.DELETE") }}
                    </b-button>
                  </b-col>-->
                </b-row>
                <v-data-table
                  v-model="canSendAsTable.selected"
                  :headers="canSendAsTableHeaders"
                  :items="canSendAsTable.members"
                  :single-select="canSendAsTable.singleSelect"
                  show-select
                  item-key="id"
                  class="elevation-1"
                  ref="canSendAsTable"
                  :footer-props="{
                    showFirstLastPage: true,
                  }"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      medium
                      color="dark"
                      @click="deleteCanSendAs(item)"
                      v-b-tooltip.hover
                      :title="$t('COMMON.DELETE')"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </b-container>
            </b-modal>
            <!-- ----------end send as permissions modal------------- -->

            <!-- ----------OEX user modal------------- -->
            <b-modal id="editOEXUser" ref="editOEXUser" :title="CEmodalTitle" size="lg">
              <b-tabs v-model="oexTabIndex" content-class="mt-3" lazy>
                <b-tab :title="$t('USERS.DETAILS')" @click="editOEXInfo(1)">
                  <b-container fluid>

                    <b-row class="my-4">
                      <b-col sm="4">
                        <label class="mb-0">{{ $t("USERS.ACCOUNT_TYPE_OEX") }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.accountType.selected"
                          value-field="value"
                          text-field="name"
                          :options="accountTypeDataForOEXdomain"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4">
                      <b-col sm="4">
                        <label class="mb-0">{{ $t("USERS.MAILBOX_SUBTYPE") }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="updateform.mailboxSubtype.selected"
                          value-field="value"
                          text-field="name"
                          :options="mailboxSubtypeData"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col sm="4">
                        <label
                          >{{ $t("COMMON.USER_NAME") }} @
                          {{ $t("COMMON.DOMAIN") }}</label
                        >
                      </b-col>
                      <b-col sm="8">
                        <b-row>
                          <b-col sm="4" class="groupFormUser">
                            <b-form-input
                              v-model="$v.updateform.pageUserName.$model"
                              :state="validateState('pageUserName')"
                              :placeholder="$t('COMMON.USER_NAME')"
                              @keydown.space.prevent
                              aria-describedby="input-pageusername-feedback"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              id="input-pageusername-feedback"
                            >
                              {{ $t("VALIDATION.USERNAME_FIELD") }}
                            </b-form-invalid-feedback>
                          </b-col>
                          <b-col sm="8" class="groupFormDomain">
                            <b-input-group prepend="@">
                              <b-form-input
                                v-model="updateItem.updateDomain"
                                value-field="domain."
                                text-field="domain"
                                disabled
                              ></b-form-input>
                            </b-input-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div>
                      <b-row class="my-4" v-if="updateform.accountType.selected != 5">
                        <b-col sm="4">
                          <label for="password" class="mb-0">{{
                            $t("COMMON.PASSWORD")
                          }}</label>
                          <p class="user-password-edit">
                            <span @click="newPassword()">{{
                              $t("COMMON.NEW")
                            }}</span
                            >&nbsp;|&nbsp;
                            <span @click="cancelPassword()">{{
                              $t("COMMON.CANCEL")
                            }}</span
                            >&nbsp;|&nbsp;
                            <span @click="copyPassword('password')">{{
                              $t("COMMON.COPY")
                            }}</span>
                          </p>
                          <!-- <p class="user-pass-dec">
                            {{ $t("USERS.PASS_DEC") }}
                          </p> -->
                        </b-col>
                        <b-col sm="8">
                          <b-form-input
                            id="password"
                            ref="password"
                            v-model="updateform.password"
                            :placeholder="$t('COMMON.PASSWORD')"
                            @input="checkPassword"
                            @focus="isShowPassDesc = true"
                            :state="valid_password"
                          ></b-form-input>
                          <ul class="ml-5 my-2" v-show="isShowPassDesc">
                            <li
                              v-bind:class="{
                                is_validPass: contains_eight_characters,
                              }"
                            >
                              {{ $t("VALIDATION.MIN_LETTERS", { num: 8 }) }}
                            </li>
                            <li v-bind:class="{ is_validPass: contains_lowercase }">
                              {{ $t("VALIDATION.LOWERCASE_LETTER") }}
                            </li>
                            <li v-bind:class="{ is_validPass: contains_uppercase }">
                              {{ $t("VALIDATION.UPPERCASE_LETTER") }}
                            </li>
                            <li v-bind:class="{ is_validPass: contains_number }">
                              {{ $t("VALIDATION.NUMBER_LETTER") }}
                            </li>
                            <li
                              v-bind:class="{
                                is_validPass: contains_special_character,
                              }"
                            >
                              {{ $t("VALIDATION.SPECIAL_LETTER") }}
                            </li>
                          </ul>
                        </b-col>
                      </b-row>
                      <b-row class="my-4">
                        <b-col sm="4">
                          <label for="nameOfUser">{{
                            $t("USERS.NAME_OF_USER")
                          }}</label>
                        </b-col>
                        <b-col sm="8">
                          <b-form-input
                            id="nameOfUser"
                            v-model="$v.updateform.nameOfUser.$model"
                            :state="validateState('nameOfUser')"
                            :placeholder="$t('USERS.NAME_OF_USER')"
                            aria-describedby="input-nameOfUser-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="input-nameOfUser-feedback">
                            {{ $t("VALIDATION.REQUIRED_FIELD") }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row class="my-4">
                        <b-col sm="4">
                          <label for="quota">{{ $t("COMMON.QUOTA") }}</label>
                        </b-col>
                        <b-col sm="8">
                          <b-form-input
                            id="quota"
                            v-model="$v.updateform.quota.$model"
                            :state="validateState('quota')"
                            type="number"
                            :placeholder="$t('COMMON.QUOTA')"
                            aria-describedby="input-quota-feedback"
                            step="0.1"
                            min="0.1"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            v-if="!$v.updateform.quota.required"
                          >
                            {{ $t("VALIDATION.REQUIRED_FIELD") }}
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback
                            v-if="!$v.updateform.quota.quotaZeroValidator"
                          >
                            {{ $t("VALIDATION.QUOTA_ZERO") }}
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback id="input-quota-feedback">
                            {{
                              $t("VALIDATION.QUOTA_FIELD", { num: maxQuotaValue() })
                            }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row class="my-4">
                        <b-col sm="4">
                          <label class="mb-0">{{ $t("COMMON.LANGUAGE") }}</label>
                        </b-col>
                        <b-col sm="8">
                          <b-form-select
                            v-model="updateform.language.selected"
                            value-field="value"
                            text-field="name"
                            :options="updateform.language.data"
                          ></b-form-select>
                        </b-col>
                      </b-row>
                    </div>
                  </b-container>
                </b-tab>
                <b-tab :title="$t('USERS.PERMISSIONS')"  @click="editOEXInfo(6)">
                  <b-container fluid>
                    <b-row>
                      <b-col>
                        <label class="mb-0">{{ $t("USERS.DELEGATES") }}</label>
                        <multi-select :options="allDomainUsers"
                                      :selected-options="oexInfo.permissions.delegates"
                                      :placeholder="$t('COMMON.SEARCH')"
                                      @select="onSelect1">
                        </multi-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <label class="mb-0">{{ $t("USERS.SEND_AS") }}</label>
                        <multi-select :options="allDomainUsers"
                                      :selected-options="oexInfo.permissions.sendas"
                                      :placeholder="$t('COMMON.SEARCH')"
                                      @select="onSelect2">
                        </multi-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <label class="mb-0">{{ $t("USERS.FULL_PERMISSION_USERS") }}</label>
                        <multi-select :options="allDomainUsers"
                                      :selected-options="oexInfo.permissions.fullpermissions"
                                      :placeholder="$t('COMMON.SEARCH')"
                                      @select="onSelect3">
                        </multi-select>
                      </b-col>
                    </b-row>
                  </b-container>

                </b-tab>
                <b-tab :title="$t('USERS.FORWARD_TYPE')" @click="editOEXInfo(2)">
                  <b-container fluid>
                    <b-row class="my-4">
                      <b-col sm="4">
                        <label class="mb-0">{{ $t("USERS.FORWARD_TYPE") }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-select
                          v-model="oexInfo.forward.forwardType"
                          value-field="value"
                          text-field="name"
                          :options="oexForwardTypeData"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="my-4">
                      <b-col sm="4">
                        <label>{{
                          $t("USERS.DESTINATION")
                        }}</label>
                      </b-col>
                      <b-col sm="8">
                        <b-form-input
                          v-model="oexInfo.forward.destination"
                          :placeholder="$t('USERS.DESTINATION')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-nameOfUser-feedback">
                          {{ $t("VALIDATION.REQUIRED_FIELD") }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                  </b-container>

                </b-tab>
              </b-tabs>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="confirmModalClose('editOEXUser')">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateOEXInfo()"
                >
                  {{ $t("USERS.UPDATE_USER") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------add user modal end------------- -->

            <!-- ----------delete confirm modal------------- -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem.email }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->

            <!-- ----------create one time password------------- -->
            <b-modal
              :title="$t('USERS.ONETIME_TITLE')"
              :hide-footer="true"
              ref="oTPass-modal"
            >
              <b-alert show variant="success">
                <h6
                  class="
                    mb-0
                    d-flex
                    justify-content-between
                    align-items-center
                    w-100
                  "
                  id="oTPcontent"
                >
                  <div style="font-size: 14px">
                    <b-badge id="oTPass">{{ oTPass }}</b-badge>
                    {{ $t("USERS.ONETIME_TITLE") }}
                  </div>

                  <b-button pill variant="primary" @click="copyOTPassword()">{{
                    $t("COMMON.COPY")
                  }}</b-button>
                </h6>
              </b-alert>
            </b-modal>
            <!-- ----------create one time password end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required, minLength } from "vuelidate/lib/validators";
import { TIMEZONES, LANGUAGES, DATEFORMATS } from "@/store/basedata.module";
import { SETSTATUS } from "@/store/status.module";
import { helpers } from "vuelidate/lib/validators";
import { MultiSelect  } from 'vue-search-select';
// const mustBeDot = value =>
//   value.lastIndexOf(".") > 0 && value.length - value.lastIndexOf(".") != 1;
/**
 * Sample widgets data source
 */
// const maxQuotaValidate = value => value <= Number(localStorage.limitQuota);
const usernameRegex = helpers.regex("alpha", /^[0-9a-zA-Z\-_.+:]+$/);
function myCustomValidator(value) {
  return (
    value <= localStorage.limitQuota * 1 + this.updateform.currentQuota * 1
  ); // should return Boolean
}
function quotaZeroValidator() {
  return (
    0 <=
    localStorage.limitQuota * 1 +
      this.updateform.currentQuota * 1 -
      this.updateform.quota * 1
  ); // should return Boolean
}
export default {
  mixins: [validationMixin],
  name: "users",
  components: {
    KTPortlet,
    MultiSelect,
  },
  data(){
    return {
      adminTimezone: "",
      adminDateFormat: "",
      userTable: {
        singleSelect: false,
        nodata: true,
        search: "",
        users: [],
        selected: [],
        itemPerPage: 10,
        total: 0,
        pagination: {},
      },
      selectDomain: {
        selectedDomain: {},
        allDomains: [],
      },
      selectAuditDomain: {
        selectedDomain: "",
        allDomains: [],
      },
      selectRegularDomain: {
        selectedDomain: "",
        allDomains: [],
      },
      permissionsForm: {
        enabledPermissions: [],
        enabledServicePermissions: [],
        domainUsers: {
          selected: "",
          data: [],
        },
        loginSchedule: [],
      },
      canSendAsTable: {
        singleSelect: false,
        members: [],
        selected: [],
      },
      showTimeRestrictions: false,
      updateform: {
        domain: {
          selected: "",
          data: [],
        },
        timeZone: {
          selected: "",
          data: [],
        },
        homeCountry: {
          selected: "",
          data: [],
        },
        travelMode: 0,
        pageUserName: "",
        password: "",
        accountType: {
          selected: 1,
          data: [],
        },
        accountTypeOEX: 3,
        nameOfUser: "",
        quota: "",
        currentQuota: "",
        language: {
          selected: "",
          data: [],
        },
        disabled: {
          selected: 0,
        },
        tfactorAuth: {
          selected: 1,
        },
        dateFormat: {
          selected: "",
          data: [],
        },
        onlylocal_smtp: {
          selected: 0,
        },
        mailboxSubtype: {
          selected: 0
        },
        domainType: '',
      },
      delItem: "",
      updateItem: {
        updateDomain: "",
        updateUserName: "",
        uid: "",
      },
      oexInfo: {
        forward: {
          forwardType: 0,
          destination: "",
        },
        permissions: {
          delegates: [],
          fullpermissions: [],
          sendas: [],
        }
      },
      validDomains: [],
      languageData: [],
      languageDataOEX: [],
      oexTabIndex: 0,
      allDomainUsers: [],
      searchText: "",
      lastSelectItem1: {},
      lastSelectItem2: {},
      lastSelectItem3: {},
      oTPass: "",
      CEmodalTitle: "",
      permissionForTitle: "",
      sendasTitle: "",
      isCreateModal: "",
      isMobile: false,
      showMore: false,
      accountsWithOptions: ["1", "2", "4", "3", "5", "OEX"],
      accountsOEX: ["3", "5"],
      isAccountWithOptions: true,
      isShowPassDesc: false,
      password_length: 0,
      contains_eight_characters: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_number: false,
      contains_special_character: false,
      valid_password: null,
      oexLangValues: {
        "de" :"de_DE",
        "es" : "es_ES",
        "en" : "en_US",
        "fr" : "fr_FR",
        "id" : "en_US",
        "pt-br": "pt_BR",
      }
    }
  },
  computed: {
    headers: function () {
      return [
        { text: this.$t("COMMON.USERNAME"), value: "username", sortable: true },
        { text: this.$t("COMMON.DOMAIN"), value: "domain", sortable: false },
        {
          text: this.$t("COMMON.TYPE"),
          value: "account_type",
          sortable: false,
        },
        {
          text: this.$t("USERS.LAST_LOGIN"),
          value: "lastauth_timestamp",
          sortable: false,
        },
        { text: this.$t("USERS.QUSAGE"), value: "quota", sortable: false },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    permissions: function () {
      return [
        {
          text: this.$t("USERS.PERM.APP_PASS"),
          value: "application_passwords",
        },
        { text: this.$t("USERS.PERM.CHANGE_PASS"), value: "change_password" },
        { text: this.$t("USERS.PERM.DELIV_RULES"), value: "delivery_rules" },
        { text: this.$t("USERS.PERM.EXTENSIONS"), value: "extensions" },
        {
          text: this.$t("USERS.PERM.FOLDERS_CLEANUP"),
          value: "folders_cleanup",
        },
        { text: this.$t("USERS.PERM.FORWARDS"), value: "forwards" },
        { text: this.$t("USERS.PERM.LAST_LOGINS"), value: "last_logins" },
        { text: this.$t("USERS.PERM.LOCAL_DELIV"), value: "local_delivery" },
        {
          text: this.$t("USERS.PERM.SPAM_QUARANTINE"),
          value: "spam_quarantine",
        },
        { text: this.$t("USERS.PERM.VACATION_MSG"), value: "vacation_message" },
      ];
    },
    servicePermissions: function () {
      return [
        { text: this.$t("USERS.PERM.SMTP"), value: "smtp" },
        { text: this.$t("USERS.PERM.IMAP"), value: "imap" },
        { text: this.$t("USERS.PERM.POP"), value: "pop3" },
      ];
    },
    storeLanguage() {
      return localStorage.language == "ptbr" ? "pt-br" : localStorage.language
    },
    labelsHours() {
      return {
        labelResetButton: this.$t("COMMON.RESET"),
        labelCloseButton: this.$t("COMMON.CLOSE"),
      };
    },
    canSendAsTableHeaders: function () {
      return [
        { text: this.$t("LISTS.MEMBER"), value: "user_email" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    accountTypeData: function () {
      return [
        { value: 1, name: this.$t("USERS.BASIC_ACCOUNT") },
        { value: 2, name: this.$t("USERS.ENHANCED_ACCOUNT") },
        { value: 'OEX', name: this.$t("USERS.OEX") },
        { value: 6, name: this.$t("USERS.EXCHANGE") },
        { value: 9, name: this.$t("USERS.OFFICE365") },
      ];
    },
    accountTypeDataForEdit: function () {
      return [
        { value: 1, name: this.$t("USERS.BASIC_ACCOUNT") },
        { value: 2, name: this.$t("USERS.ENHANCED_ACCOUNT") },
      ];
    },
    accountTypeDataForAuditDomain: function () {
      return [
        { value: 4, name: this.$t("USERS.AUDIT_ACCOUNT")},
      ];
    },
    accountTypeDataForOEXdomain: function () {
      return [
        { value: 3, name: this.$t("USERS.NORMAL") },
        { value: 5, name: this.$t("USERS.SHARED") },
        //{ value: 7, name: this.$t("PUBLIC_FOLDERS.PUBLIC_FOLDER") },
      ];
    },
    disabledData: function () {
      return [
        { value: 0, name: this.$t("COMMON.NO") },
        { value: 1, name: this.$t("COMMON.YES") },
        { value: 2, name: this.$t("USERS.DISABLE_SMTP_ONLY") },
      ];
    },
    onlylocal_smtpData: function () {
      return [
        { value: 0, name: this.$t("COMMON.NO") },
        { value: 1, name: this.$t("COMMON.YES") },
      ];
    },
    tfactorAuthData: function () {
      return [
        { value: 0, name: this.$t("COMMON.NOT_ALLOWED") },
        { value: 1, name: this.$t("COMMON.ALLOWED") },
      ];
    },
    travelModeData: function () {
      return [
        { value: 0, text: this.$t("COMMON.INACTIVE") },
        { value: 1, text: this.$t("COMMON.ACTIVE") },
      ];
    },
    mailboxSubtypeData: function () {
      return [
        { value: 0, name: this.$t("USERS.NORMAL") },
        { value: 7, name: this.$t("USERS.ROOM") },
        { value: 8, name: this.$t("USERS.EQUIPMENT") },
      ];
    },
    oexForwardTypeData: function () {
      return [
        {value: 0, name: this.$t("USERS.CC")},
        {value: 1, name: this.$t("USERS.REDIRECT")},
      ];
    },
    canAdd: function () {
      return this.hasPermission("users", 5);
    },
    canEnable: function () {
      return this.hasPermission("users", 6);
    },
    canRemove: function () {
      return this.hasPermission("users", 7);
    },
    canImpersonate: function () {
      return this.hasPermission("users", 8);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        this.validDomains = [];
        if (!res.returncode) {
          this.validDomains = [{ domain: res.returndata }];
        } else {
          res.returndata.forEach((el) => {
            if (el.verification == "0"){
              this.validDomains.push(el);
            }
          }, this);
        }
        this.selectRegularDomain.allDomains = this.validDomains;
        this.selectRegularDomain.selectedDomain = this.validDomains[0].domain;

        postRequest({
          action: "getAllAuditDomains",
          token: localStorage.id_token,
        }).then((res) => {
          if (res) {
            if (res.returncode) {
              this.selectAuditDomain.allDomains = res.returndata;
              this.selectAuditDomain.selectedDomain = res.returndata[0].domain;
            }
          }

          // merge the two
          this.selectDomain.allDomains = this.selectAuditDomain.allDomains.concat(this.selectRegularDomain.allDomains);
          this.selectDomain.selectedDomain = this.selectDomain.allDomains[0];

          this.changeFormDomain();
          this.getUsersReqest(
            0,
            this.userTable.itemPerPage,
            0,
            1,
            this.selectDomain.selectedDomain.domain
          );
        });
      }
    });

    let stateTimezones = this.$store.state.basedata.timezones;
    if (Object.keys(stateTimezones).length == 0) {
      this.$store
        .dispatch(TIMEZONES, {
          action: "getTimezones",
          token: localStorage.id_token,
        })
        .then((res) => {
          if (res.returncode) {
            this.updateform.timeZone.data = res.returndata;
          }
        });
    } else {
      this.updateform.timeZone.data = stateTimezones;
    }

    /* get languages - OEX has different formats */
    let stateLanguages = this.$store.state.basedata.languages;
    let stateDateformats = this.$store.state.basedata.dateformats;
    if (Object.keys(stateLanguages).length == 0) {
      this.$store
        .dispatch(LANGUAGES, {
          action: "getLanguages",
          token: localStorage.id_token,
        })
        .then((res) => {
          if (res.returncode) {
            let updatedReturnData = [];
            for (const [key, value] of Object.entries(res.returndata)) {
              updatedReturnData.push({ value: key, name: value });
            }
            this.languageData = updatedReturnData;
          }
        });
    } else  {
      this.languageData = stateLanguages;
    }

    postRequest({
      action: "getOEXLanguages",
      token: localStorage.id_token,
    })
    .then((res) => {
      if (res.returncode) {
        let updatedReturnData = [];
        for (const [key, value] of Object.entries(res.returndata)) {
          updatedReturnData.push({ value: key, name: value });
        }
        this.languageDataOEX = updatedReturnData;
      }
    }); 

    if (Object.keys(stateDateformats).length == 0) {
      this.$store
        .dispatch(DATEFORMATS, {
          action: "getDateFormats",
          token: localStorage.id_token,
        })
        .then((res) => {
          if (res.returncode) {
            let updatedReturnData = [];
            let i;
            for (i in res.returndata) {
              updatedReturnData.push({ value: res.returndata[i] });
            }
            this.updateform.dateFormat.data = updatedReturnData;
          }
        });
    } else {
      this.updateform.dateFormat.data = stateDateformats;
    }

    postRequest({
      action: "getCountries",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ text: res.returndata }];
        } else {
          for (var key in res.returndata) {
            this.updateform.homeCountry.data.push({
              value: key,
              text: res.returndata[key],
            });
          }
        }
      }
    });

    if (window.innerWidth < 600) this.isMobile = true;
    window.addEventListener("resize", this.resizeWin);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWin);
  },
  validations: {
    updateform: {
      pageUserName: {
        required,
        minLength: minLength(2),
        usernameRegex,
      },
      nameOfUser: {
        required,
      },
      quota: {
        required,
        myCustomValidator,
        quotaZeroValidator,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.USERS") }]);
    this.$store.dispatch(SETSTATUS, {
      action: "getAdminStats",
      token: localStorage.id_token,
    });
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.updateform[name];
      return $dirty ? !$error : null;
    },
    maxQuotaValue() {
      return (
        localStorage.limitQuota * 1 +
        this.updateform.currentQuota * 1
      ).toFixed(2);
    },
    checkPassword() {
      this.password_length = this.updateform.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }
      this.contains_number = /\d/.test(this.updateform.password);
      this.contains_uppercase = /[A-Z]/.test(this.updateform.password);
      this.contains_lowercase = /[a-z]/.test(this.updateform.password);
      this.contains_special_character = format.test(this.updateform.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true &&
        this.contains_lowercase === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    pBarColor(str) {
      const tt = str.replace("%", "");
      return tt < 50 ? "primary" : tt < 90 ? "warning" : "danger";
    },
    progressNum(quota, usage) {
      const result =
        Math.round(
          (quota / 1000 - ((quota / 1000) * usage.replace("%", "")) / 100) *
            1000
        ) / 1000;
      return result;
    },
    resizeWin() {
      if (window.innerWidth < 600) this.isMobile = true;
      else this.isMobile = false;
    },
    /*  -----form reset------- */
    resetForm() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.updateform.accountType.selected = 1;
      this.updateform.accountTypeOEX = 3;
      this.updateform.timeZone.selected =
      this.updateform.timeZone.data[0].timezone;
      this.updateform.homeCountry.selected =
        'UN'; // UNKNOWN
      this.updateform.travelMode = 0;
      this.updateform.pageUserName = "";
      this.updateform.password = "";
      this.updateform.mailboxSubtype.selected = 0;
      this.updateform.nameOfUser = "";
      this.updateform.quota = "";
      this.updateform.language.selected = this.storeLanguage;
      this.updateform.disabled.selected = 0;
      this.updateform.tfactorAuth.selected = localStorage.isTfaAllowed;
      this.updateform.dateFormat.selected = "";
      this.isAccountWithOptions = true;
      this.valid_password = null;
      this.changeFormDomain();

      this.allDomainUsers = [];
      this.oexInfo.forward.forwardType = 0;
      this.oexInfo.forward.destination = "";
      this.oexInfo.permissions.delegates = [];
      this.oexInfo.permissions.sendas = [];
      this.oexInfo.permissions.fullpermissions = [];
    },
    onSelect1 (items, lastSelectItem) {
      this.oexInfo.permissions.delegates = items;
      this.lastSelectItem1 = lastSelectItem;
    },
    onSelect2 (items, lastSelectItem) {
      this.oexInfo.permissions.sendas = items;
      this.lastSelectItem2 = lastSelectItem;
    },
    onSelect3 (items, lastSelectItem) {
      this.oexInfo.permissions.fullpermissions = items;
      this.lastSelectItem3 = lastSelectItem;
    },
    resetPermissionsForm() {
      this.permissionsForm.username = "";
      this.permissionsForm.domain = "";
      this.permissionsForm.enabledPermissions = [];
      this.permissionsForm.enabledServicePermissions = [];
    },
    defaultLoginSchedule() {
      var sch = [];
      for (let i = 0; i < 7; i++) {
        sch[i] = {
          day: i + 1,
          loginStartHour: "",
          loginEndHour: "",
          allowed: false,
        };
      }

      return sch;
    },
    /*  -----modal close------- */
    modalClose() {
      this.$refs["addUser"].hide();
      this.resetForm();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ---------read item---------- */
    getUsersReqest(offset, limit, Sorting, slicing, domain) {
      this.userTable.users = [];
      this.userTable.selected = [];
      postRequest({
        action: "getAllUsersDomain",
        token: localStorage.id_token,
        offset: offset,
        limit: limit,
        sorting: Sorting,
        slicing: limit > 0 ? slicing : 0,
        domain: domain,
        getOTPAndToken: 0,
        showQuota: 1,
      }).then((res) => {
        if (res.returncode) {
          this.userTable.users = res.returndata;
          this.userTable.total = res.meta.all;
          this.userTable.nodata = false;
        } else {
          this.userTable.users = [];
          this.userTable.total = 0;
          this.userTable.nodata = true;
        }
      });
    },
    getUsersForSelectedDomain(selectedDomainArg) {
      this.getUsersReqest(
        0,
        this.userTable.itemPerPage,
        0,
        1,
        selectedDomainArg
      );
    },
    changePerPage(arg) {
      this.userTable.itemPerPage = arg;
      if (arg == -1) {
        this.getUsersReqest(0, arg, 0, 0, this.selectDomain.selectedDomain.domain);
      } else {
        this.getUsersReqest(0, arg, 0, 1, this.selectDomain.selectedDomain.domain);
      }
    },
    searchUser() {
      this.userTable.users = [];
      this.userTable.selected = [];
      clearTimeout(this._cusDebounce);
      // delay new call 500ms
      this._cusDebounce = setTimeout(() => {
        postRequest({
          action: "searchResults",
          token: localStorage.id_token,
          search: this.userTable.search,
          Offset: 0,
          limit: this.userTable.itemPerPage,
          slicing: this.userTable.itemPerPage > 0 ? 1 : 0,
          showQuota: 1,
        }).then((res) => {
          if (res.returncode) {
            this.userTable.users = res.returndata;
            this.userTable.nodata = false;
          } else {
            this.userTable.users = [];
            this.userTable.nodata = true;
          }
        });
      }, 800);
    },
    getCategoriesByPagination() {
      const pageOffset =
        (this.userTable.pagination.page - 1) * this.userTable.itemPerPage;
      this.getUsersReqest(
        pageOffset,
        this.userTable.itemPerPage,
        0,
        1,
        this.selectDomain.selectedDomain.domain
      );
    },
    getCategoriesBySort() {
      const sortDesc = this.userTable.pagination.sortDesc[0];
      if (sortDesc) {
        this.getUsersReqest(
          0,
          this.userTable.itemPerPage,
          0,
          1,
          this.selectDomain.selectedDomain.domain
        );
      } else if (sortDesc == false) {
        this.getUsersReqest(
          0,
          this.userTable.itemPerPage,
          1,
          1,
          this.selectDomain.selectedDomain.domain
        );
      }
    },

    /* ------create item---- */
    async openCreateModal() {
      this.resetForm();
      this.isCreateModal = true;
      this.CEmodalTitle = this.$t("USERS.ADD_USER");
      this.updateform.currentQuota = 0;

      /* set initial timezone */
      if (this.adminTimezone == "") {
        await postRequest({
          action: "getAdminTimezone",
          token: localStorage.id_token,
        }).then((res) => {
          if (res) {
            this.updateform.timeZone.selected = res;
            this.adminTimezone = res;
          } else {
            this.makeToastVariant("danger", res);
          }
        });
      } else {
        this.updateform.timeZone.selected = this.adminTimezone;
      }

      /* set initial dateformat */
      if (this.adminDateFormat == "") {
        await postRequest({
          action: "getAdminDateFormat",
          token: localStorage.id_token,
        }).then((res) => {
          if (res && res.returncode) {
            this.updateform.dateFormat.selected = res.returndata.dateformat;
            this.adminDateFormat = res.returndata.dateformat;
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      } else {
        this.updateform.dateFormat.selected = this.adminDateFormat;
      }

      this.changeAccountType(this.updateform.accountType.selected);

      this.$refs["addUser"].show();
      this.updateform.tfactorAuth.selected = localStorage.isTfaAllowed;
      this.updateform.language.selected = this.storeLanguage;
    },
    changeAccountType(selectedType) {
      this.isAccountWithOptions = false;
      if (this.accountsWithOptions.includes(selectedType.toString())) {
        this.isAccountWithOptions = true;
      }
      if (this.updateform.accountType.selected == 'OEX'){
        this.updateform.language.data = this.languageDataOEX;
        this.updateform.language.selected = this.oexLangValues[this.storeLanguage];
      } else {
        this.updateform.language.data = this.languageData;
        this.updateform.language.selected = this.storeLanguage;
      }
    },
    changeFormDomain() {
      this.updateform.domain.data = [this.selectDomain.selectedDomain.domain];
      this.updateform.domain.selected = this.selectDomain.selectedDomain.domain; 
      if (this.selectDomain.selectedDomain.isauditdomain == 0) {
        this.updateform.domainType = 'REGULAR';
        this.updateform.accountType.data = this.accountTypeData;
        this.updateform.accountType.selected = 1; // basic
      } else {
        this.updateform.domainType = 'AUDIT';
        this.updateform.accountType.data = this.accountTypeDataForAuditDomain;
        this.updateform.accountType.selected = 4; // audit
      } 
    },
    /* password system */
    newPassword() {
      postRequest({
        action: "getRandomPassword",
        token: localStorage.id_token,
      }).then((res) => {
        if (res.returncode) {
          this.updateform.password = res.returndata;
          this.valid_password = true;
          this.contains_eight_characters = true;
          this.contains_special_character = true;
          this.contains_uppercase = true;
          this.contains_number = true;
          this.contains_lowercase = true;
        }
      });
    },
    cancelPassword() {
      this.updateform.password = "";
    },
    copyPassword(id) {
      var copyText = document.getElementById(id);
      copyText.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
    copyOTPassword() {
      var copyText = document.getElementById("oTPass");
      var textArea = document.createElement("textarea");
      textArea.value = copyText.textContent;
      document.getElementById("oTPcontent").appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();
    },
    /* password system end*/
    createItem() {
      if (this.isAccountWithOptions) {
        this.$v.updateform.$touch();
        if (this.$v.updateform.$anyError) {
          return;
        }

        if (
          this.updateform.accountType.selected !== 'OEX' || 
          this.updateform.accountTypeOEX !== 5 /*shared*/
        ) {
          if (
            this.contains_eight_characters === false ||
            this.contains_special_character === false ||
            this.contains_uppercase === false ||
            this.contains_number === false ||
            this.contains_lowercase === false
          ) {
            this.$refs["password"].focus();
            return;
          }
        }
      } else {
        this.$v.updateform.pageUserName.$touch();
        if (this.$v.updateform.pageUserName.$anyError) {
          return;
        }
      }

      let req = {
        action: "addUser",
        token: localStorage.id_token,
        quota: this.updateform.quota,
        account_type: this.updateform.accountType.selected,
        username: this.updateform.pageUserName,
        domain: this.updateform.domain.selected,
        password: this.updateform.password,
        uname: this.updateform.nameOfUser,
        twofa_allowed: this.updateform.tfactorAuth.selected,
        user_language: this.updateform.language.selected,
        timezone: this.updateform.timeZone.selected,
        dateformat: this.updateform.dateFormat.selected,
      };

      if (this.updateform.accountType.selected == 'OEX') {
        req.account_type = this.updateform.accountTypeOEX;
        req.account_subtype = this.updateform.mailboxSubtype.selected;
        delete req.twofa_allowed;
        delete req.timezone;
        delete req.dateformat;
        if (this.updateform.accountTypeOEX == 5) {
          // oex type shared
          delete req.password;
        }
      }

      postRequest(req).then((res) => {
        if (res.returncode) {
          this.makeToastVariant(
            "success",
            res.returndata + ". " + this.$t("COMMON.WAIT_MESSAGE")
          );
          this.getUsersReqest(
            0,
            this.userTable.itemPerPage,
            0,
            1,
            this.selectDomain.selectedDomain.domain
          );
          this.$store.dispatch(SETSTATUS, {
            action: "getAdminStats",
            token: localStorage.id_token,
          });
          this.modalClose();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ------create item end---- */
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },
    deleteOneItem() {
      const item = this.delItem;
      postRequest({
        action: "removeUser",
        token: localStorage.id_token,
        domain: item.domain,
        username: item.username,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.getUsersReqest(
            0,
            this.userTable.itemPerPage,
            0,
            1,
            this.selectDomain.selectedDomain.domain
          );
          this.$store.dispatch(SETSTATUS, {
            action: "getAdminStats",
            token: localStorage.id_token,
          });
          this.makeToastVariant(
            "success",
            res.returndata + ". " + this.$t("COMMON.WAIT_MESSAGE")
          );
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteItems() {
      if (this.userTable.selected.length > 0) {
        var itemsProcessed = 0;
        var selectedLength = this.userTable.selected.length;
        this.userTable.selected.forEach(async (el) => {
          await postRequest({
            action: "removeUser",
            token: localStorage.id_token,
            domain: el.domain,
            username: el.username,
          }).then((res) => {
            itemsProcessed++;
            this.confirmModalClose("delConfirm-modal");
            if (res.returncode) {
              this.makeToastVariant(
                "success",
                res.returndata + ". " + this.$t("COMMON.WAIT_MESSAGE")
              );
            } else {
              this.makeToastVariant("danger", res.returndata);
            }

            if (itemsProcessed === selectedLength) {
              this.getUsersReqest(
                0,
                this.userTable.itemPerPage,
                0,
                1,
                this.selectDomain.selectedDomain.domain
              );
              this.$store.dispatch(SETSTATUS, {
                action: "getAdminStats",
                token: localStorage.id_token,
              });
            }
          });
        });
      }
    },

    /* -----one time password------ */
    createOtPass(item) {
      postRequest({
        action: "getOTPass",
        token: localStorage.id_token,
        domain: item.domain,
        username: item.username,
      }).then((res) => {
        if (res.returncode) {
          this.oTPass = res.returndata;
          this.$refs["oTPass-modal"].show();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* -----one time password end------ */

    /* ------item permissions----- */
    showItemPermissions(item) {
      this.resetPermissionsForm();
      this.permissionsForm.username = item.username;
      this.permissionsForm.domain = item.domain;
      this.permissionForTitle =
        this.$t("USERS.PERM.PERMISSIONS") +
        ": " +
        item.username +
        "@" +
        item.domain;
      postRequest({
        action: "getUserPermissions",
        token: localStorage.id_token,
        domain: item.domain,
        username: item.username,
      }).then((res) => {
        if (res.returncode) {
          this.permissions.forEach((item) => {
            if (res.returndata.permissions[item.value] == 1) {
              this.permissionsForm.enabledPermissions.push(item.value);
            }
          });
          this.servicePermissions.forEach((item) => {
            if (res.returndata.service_permissions[item.value] == 1) {
              this.permissionsForm.enabledServicePermissions.push(item.value);
            }
          });
          this.$refs["userPermissions"].show();
        }
      });
    },
    /* ------item permissions end----- */

    /* -------update the user permissions------- */
    updateUserPermissions() {
      const username = this.permissionsForm.username;
      const domain = this.permissionsForm.domain;

      let req = {
        action: "setUserPermissions",
        token: localStorage.id_token,
        username: username,
        domain: domain,
        permissions: {
          application_passwords: 0,
          change_password: 0,
          delivery_rules: 0,
          extensions: 0,
          folders_cleanup: 0,
          forwards: 0,
          last_logins: 0,
          local_delivery: 0,
          spam_quarantine: 0,
          vacation_message: 0,
        },
        service_permissions: {
          imap: 0,
          pop3: 0,
          smtp: 0,
        },
      };

      this.permissionsForm.enabledPermissions.forEach((item) => {
        req.permissions[item] = 1;
      });
      this.permissionsForm.enabledServicePermissions.forEach((item) => {
        req.service_permissions[item] = 1;
      });

      postRequest(req).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ------hour permissions----- */
    showHourPermissions() {
      this.permissionsForm.loginSchedule = this.defaultLoginSchedule();
      this.showTimeRestrictions = false;

      postRequest({
        action: "getUserTimeRestrictions",
        token: localStorage.id_token,
        domain: this.permissionsForm.domain,
        username: this.permissionsForm.username,
      }).then((res) => {
        if (res.returncode) {
          let sch = res.returndata;
          var item;
          for (item in sch) {
            if (sch[item].start_time === "" && sch[item].end_time === "") {
              // default or unset: unlimited access
              this.permissionsForm.loginSchedule[item - 1].allowed = true;
            } else if (sch[item].start_time === sch[item].end_time) {
              // same start and end hour: access not allowed
              this.permissionsForm.loginSchedule[item - 1].allowed = false;
            } else {
              this.permissionsForm.loginSchedule[item - 1].allowed = true;
              if (sch[item].start_time =="00:00" && sch[item].end_time == "23:59") {
                // show unrestricted to end-user to eliminate second-guessing
                this.permissionsForm.loginSchedule[item - 1].loginStartHour = "";
                this.permissionsForm.loginSchedule[item - 1].loginEndHour = "";
              } else {
                this.permissionsForm.loginSchedule[item - 1].loginStartHour =
                  sch[item].start_time;
                this.permissionsForm.loginSchedule[item - 1].loginEndHour =
                  sch[item].end_time;
              }
              this.permissionsForm.loginSchedule[item - 1].day = item;
              this.showTimeRestrictions = true;
            }
          }
          this.$refs["hourPermissions"].show();
        }
      });
    },
    /* ------hour permissions end----- */

    /* -------update the hour permissions------- */
    updateHourPermissions() {
      var sch = [];
      var removeTimeRestrictions = 1;
      var unrestricted = 0;
      // if user enabled the daily schedule, we should take into consideration all details
      if (this.showTimeRestrictions) removeTimeRestrictions = 0;
      this.permissionsForm.loginSchedule.forEach((el) => {
        let idx = el.day;
        if (el.allowed) {
          if (el.loginStartHour === "" || el.loginEndHour === "") {
            sch[idx] = {
              start_time: "00:00",
              end_time: "23:59",
            };
            unrestricted++;
          } else {
            sch[idx] = {
              start_time: el.loginStartHour.substring(0, 5),
              end_time: el.loginEndHour.substring(0, 5),
            };
          }
        } else {
          sch[idx] = {
            start_time: "00:00",
            end_time: "00:00",
          };
        }
      });
      // if all days are unrestricted, we should remove all time restrictions
      if (unrestricted==7) removeTimeRestrictions=1;
      postRequest({
        action: "setUserTimeRestrictions",
        token: localStorage.id_token,
        username: this.permissionsForm.username,
        domain: this.permissionsForm.domain,
        timeRestrictions: sch,
        removeTR: removeTimeRestrictions,
      }).then((res) => {
        if (res.returncode) {
          this.confirmModalClose("hourPermissions");
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* -------update the hour permissions end------- */

    stateHours: function (day) {
      if (day.allowed && day.loginStartHour === day.loginEndHour) return false;
      if (day.allowed && day.loginStartHour >= day.loginEndHour) return false;
      if (
        (day.loginStartHour === "" && day.loginEndHour !== "") ||
        (day.loginStartHour !== "" && day.loginEndHour === "")
      )
        return false;
      return true;
    },
    invalidHoursFeedback: function (day) {
      if (
        day.loginStartHour >= day.loginEndHour &&
        day.loginStartHour !== "" &&
        day.loginEndHour !== ""
      ) {
        return this.$t("USERS.PERM.HOURS_SIZE");
      } else if (
        (day.loginStartHour === "" && day.loginEndHour !== "") ||
        (day.loginStartHour !== "" && day.loginEndHour === "")
      ) {
        return this.$t("USERS.PERM.HOURS_BOTH");
      }
    },

        /* ------send as permissions----- */
    showSendAsPermissions() {
      const editUserEmail = this.permissionsForm.username + "@" + this.permissionsForm.domain;
      
      this.sendasTitle = this.$t("USERS.PERM.CAN_SEND_AS") + editUserEmail;
      this.canSendAsTable.members = [];
      this.canSendAsTable.selected = [];
      postRequest({
        action: "getSendAsUsers",
        token: localStorage.id_token,
        user_sendas: editUserEmail,
      }).then((res) => {
        if (res.returncode) {
          this.canSendAsTable.members = res.returndata;
        }

        this.permissionsForm.domainUsers.data = [];
        postRequest({
          action: "getAllUsersDomain",
          token: localStorage.id_token,
          domain: this.permissionsForm.domain,
          offset: 0,
          limit: 0,
          sorting: 0,
          slicing: 0,
          getOTPAndToken: 0,
          showQuota: 0,
        }).then((res) => {
          if (res.returncode) {
            this.permissionsForm.domainUsers.selected = res.returndata[0].email;
            res.returndata.forEach((el) => {
              if (el.account_type == 1 || el.account_type == 2) {
                this.permissionsForm.domainUsers.data.push({
                  value: el.email,
                  text: el.email,
                });
              }
            });
          } else {
            this.permissionsForm.domainUsers.selected = 1;
          }
        });

        
        this.$refs["sendasPermissions"].show();
      });
    },
    /* ------send as permissions end----- */

    /* ------add send as permissions----- */
    addSendAsPermissions() {
      const editUserEmail = this.permissionsForm.username + "@" + this.permissionsForm.domain;

      const userWithPermission = this.permissionsForm.domainUsers.selected;

      postRequest({
        action: "addSendAsUser",
        token: localStorage.id_token,
        user_sendas: editUserEmail,
        user_email: userWithPermission,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          postRequest({
            action: "getSendAsUsers",
            token: localStorage.id_token,
            user_sendas: editUserEmail,
          }).then((res) => {
            if (res.returncode) {
              this.canSendAsTable.members = res.returndata;
            } else{
              this.canSendAsTable.members = [];
            }
          });
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ------add send as end----- */

    /* ------delete send as end----- */
    deleteCanSendAs(item) {
      const editUserEmail = this.permissionsForm.username + "@" + this.permissionsForm.domain;
      const userWithPermission = item.user_email;

      postRequest({
        action: "deleteSendAsUser",
        token: localStorage.id_token,
        user_sendas: editUserEmail,
        user_email: userWithPermission,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          postRequest({
            action: "getSendAsUsers",
            token: localStorage.id_token,
            user_sendas: editUserEmail,
          }).then((res) => {
            if (res.returncode) {
              this.canSendAsTable.members = res.returndata;
            } else{
              this.canSendAsTable.members = [];
            }
          });
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });

    },
    /* ------delete send as end----- */

    /* ------edit item----- */
    async showItemInfor(item) {
      /*this.$nextTick(() => {
        this.$v.$reset();
      });*/
      this.resetForm();

      // if item.account_type is of type OEX
      if (this.accountTypeDataForOEXdomain.find(o => o.value == item.account_type)) {
        this.showOexUserInfo(item, "details");
      }
      else {
        // regular user
        postRequest({
          action: "getUserInfo",
          token: localStorage.id_token,
          domain: item.domain,
          username: item.username,
        }).then((res) => {
          if (res.returncode) {
            this.isCreateModal = false;
            this.CEmodalTitle =
              this.$t("COMMON.UPDATE") +
              res.returndata.username +
              "@" +
              res.returndata.domain;
            this.updateItem.updateUserName = res.returndata.username;
            this.updateItem.updateDomain = res.returndata.domain;
            this.updateItem.uid = item.id;
            this.$refs["addUser"].show();
            this.updateform.timeZone.selected = res.returndata.timezone;
            this.updateform.homeCountry.selected = res.returndata.homecountry;
            this.updateform.travelMode = res.returndata.travelmode;
            this.updateform.password = "";
            this.updateform.accountType.selected = res.returndata.account_type;
            this.updateform.nameOfUser = res.returndata.uname;
            this.updateform.quota = res.returndata.quota / 1000;
            this.updateform.currentQuota = res.returndata.quota / 1000;
            this.updateform.language.data = this.languageData;
            this.updateform.language.selected = res.returndata.language;
            this.updateform.disabled.selected = res.returndata.disabled;
            this.updateform.tfactorAuth.selected =
              res.returndata["2fa_allowed"];
            this.updateform.dateFormat.selected = res.returndata.dateformat;
            this.updateform.onlylocal_smtp.selected =
              res.returndata.localsmtponly;
            //this.changeFormDomain();
            this.updateform.accountType.data = this.accountTypeDataForEdit;
            this.changeAccountType(this.updateform.accountType.selected);
          }
        });
      }
    },
    updateItemInfor() {
      this.$v.updateform.$touch();
/*
      if (this.$v.updateform.$anyError) {
        return;
      }
*/
      const editusername = this.updateItem.updateUserName;
      const editdomain = this.updateItem.updateDomain;
      postRequest({
        action: "updateUser",
        token: localStorage.id_token,
        editusername: editusername,
        editdomain: editdomain,
        edituid: this.updateItem.uid,
        editpassword: this.updateform.password,
        editaccount_type: this.updateform.accountType.selected,
        edituname: this.updateform.nameOfUser,
        editquota: this.updateform.quota,
        edituser_language: this.updateform.language.selected,
        editdisabled: this.updateform.disabled.selected,
        edit2fa_allowed: this.updateform.tfactorAuth.selected,
        edit_timezone: this.updateform.timeZone.selected,
        homecountry: this.updateform.homeCountry.selected,
        travelmode: this.updateform.travelMode,
        edit_dateformat: this.updateform.dateFormat.selected,
        localsmtponly: this.updateform.onlylocal_smtp.selected,
      }).then((res) => {
        if (res.returncode) {
          this.getUsersReqest(
            0,
            this.userTable.itemPerPage,
            0,
            1,
            this.selectDomain.selectedDomain.domain
          );
          this.$store.dispatch(SETSTATUS, {
            action: "getAdminStats",
            token: localStorage.id_token,
          });
          this.$refs["addUser"].hide();
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    populateOexUserInfo(returndata){
      this.updateform.password = "";
      this.updateform.accountType.selected = returndata.account_type;
      this.updateform.mailboxSubtype.selected = returndata.oex_subtype;
      this.updateform.nameOfUser = returndata.uname;
      this.updateform.quota = returndata.quota / 1000;
      this.updateform.currentQuota = returndata.quota / 1000;
      this.updateform.language.data = this.languageDataOEX;
      this.updateform.language.selected = returndata.language;
    },
    showOexUserInfo(item, filter){
      this.isCreateModal = false;
      this.CEmodalTitle =
        this.$t("COMMON.UPDATE") +
        item.username +
        "@" +
        item.domain;
      this.updateItem.updateUserName = item.username; //res.returndata.username;
      this.updateform.pageUserName = item.username; //res.returndata.username;
      this.updateItem.updateDomain = item.domain; //res.returndata.domain;
      this.updateItem.uid = item.id;
      if (filter == "forwards") {
        this.editOEXInfo(2);
        this.oexTabIndex = 2;
      } else if (filter == "permissions") {
        this.editOEXInfo(6);
        this.oexTabIndex = 1;
      } else if (filter == "details") {
        this.editOEXInfo(1);
        this.oexTabIndex = 0;
      }
      this.$refs["editOEXUser"].show();
    },
    
    /* ------edit OEX permissions-------*/
    editOEXInfo(filter){
      postRequest({
        action: "getOEXUserInfo",
        token: localStorage.id_token,
        domain: this.updateItem.updateDomain,
        username: this.updateItem.updateUserName,
        filter: filter, // 1 - user info, 6 - permissions, 2 - forwards
      }).then((res) => {
        if (res.returncode) {
          if (filter == 1){
            this.populateOexUserInfo(res.returndata);
          } else if (filter == 2){
            if (res.returndata.forward) {
              this.oexInfo.forward.forwardType = res.returndata.forward.forwardType;
              this.oexInfo.forward.destination = res.returndata.forward.destination;
            }
          } else if (filter == 6) {
            this.oexInfo.permissions.delegates = res.returndata.delegates.map(el => ({value: el, text: el}));
            this.oexInfo.permissions.fullpermissions = res.returndata.fullpermissions.map(el => ({value: el.username, text: el.username}));
            this.oexInfo.permissions.sendas = res.returndata.sendas.map(el => ({value: el, text: el}));
            this.allDomainUsers = [];
            postRequest({
              action: "getAllUsersDomain",
              token: localStorage.id_token,
              domain: this.updateItem.updateDomain,
              offset: 0,
              limit: 0,
              sorting: 0,
              slicing: 0,
              getOTPAndToken: 0,
              showQuota: 0,
            }).then((res) => {
              if (res.returncode) {
                let itemEmail = this.updateItem.updateUserName + "@" + this.updateItem.updateDomain;
                res.returndata.forEach((el) => {
                  if (el.account_type == 3 && el.email !== itemEmail) {
                    this.allDomainUsers.push({value: el.email, text: el.email});
                  }
                });
              }
            });
          }
        }
      });
    },

    updateOEXInfo() {
      let req = {
        token: localStorage.id_token,
        uid: this.updateItem.uid,
        username: this.updateItem.updateUserName,
        domain: this.updateItem.updateDomain,
      };
      if (this.oexTabIndex == 0){ // user info
        this.$v.updateform.$touch();
        if (this.$v.updateform.$anyError) {
          return;
        }
        req.action = "updateOEXUser";
        req.username = this.updateform.pageUserName;
        req.account_type = this.updateform.accountType.selected;
        req.account_subtype = this.updateform.mailboxSubtype.selected;
        req.password = this.updateform.password;
        req.quota = this.updateform.quota;
        req.uname = this.updateform.nameOfUser;
        req.user_language = this.updateform.language.selected;

        postRequest(req).then((res) => {
          if (res.returncode) {
            this.getUsersReqest(
              0,
              this.userTable.itemPerPage,
              0,
              1,
              this.selectDomain.selectedDomain.domain
            );
            this.$store.dispatch(SETSTATUS, {
              action: "getAdminStats",
              token: localStorage.id_token,
            });
            this.$refs["editOEXUser"].hide();
            this.makeToastVariant("success", res.returndata);
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      } else if (this.oexTabIndex == 1){ // user permissions
        let reqArr =[Object.assign({}, req), Object.assign({}, req), Object.assign({}, req)];
        reqArr[0].action = "addOEXUserSendas";
        reqArr[0].sendas = this.oexInfo.permissions.sendas.length > 0 ? 
          this.oexInfo.permissions.sendas.map(el => (el.value)) : null;
        reqArr[1].action = "addOEXUserDelegate";
        reqArr[1].delegates = this.oexInfo.permissions.delegates.length > 0 ?
          this.oexInfo.permissions.delegates.map(el => (el.value)) : null;
        reqArr[2].action = "addOEXUserFullPermissions";
        reqArr[2].fullperms = this.oexInfo.permissions.fullpermissions.length > 0 ? 
          this.oexInfo.permissions.fullpermissions.map(el => (el.value)) : null;

        reqArr.forEach((r) => {
          postRequest(r).then((res) => {
            if (res.returncode) {
              this.makeToastVariant("success", res.returndata);
            } else {
              this.makeToastVariant("danger", res.returndata);
            }
          });
        });        
        this.$refs["editOEXUser"].hide();
      } else if (this.oexTabIndex == 2){ // user forwards
        req.action = "addOEXUserForward";
        req.forward_type = this.oexInfo.forward.forwardType;
        req.forward = this.oexInfo.forward.destination;

        postRequest(req).then((res) => {
          if (res.returncode) {
            this.$refs["editOEXUser"].hide();
            this.makeToastVariant("success", res.returndata);
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      }
    },

    /* -------go to new adminpage------- */
    goNewAdmin(item) {
      postRequest({
        action: "be",
        token: localStorage.id_token,
        uid: item.id,
      }).then((res) => {
        if (res.returncode) {
            window.open(
              res.returnurl + "/home.php?token=" + res.returndata
            );
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* -------get otp and go to new adminpage------- */
    getOTPassAndToken(item) {
      postRequest({
        action: "getOTPassAndToken",
        token: localStorage.id_token,
        username: item.username,
        domain: item.domain,
      }).then((res) => {
        if (res && res.returncode) {
          var form = document.createElement("form");
          form.setAttribute("method", "POST");
          form.setAttribute("action", "https://" + res.returndata.url);

          form.setAttribute("target", "view");

          var hiddenField1 = document.createElement("input");
          hiddenField1.setAttribute("type", "hidden");
          hiddenField1.setAttribute("name", "accessToken");
          hiddenField1.setAttribute("value", res.returndata.token);
          var hiddenField2 = document.createElement("input");
          hiddenField2.setAttribute("type", "hidden");
          hiddenField2.setAttribute("name", "password");
          hiddenField2.setAttribute("value", res.returndata.otp);
          form.appendChild(hiddenField1);
          form.appendChild(hiddenField2);
          document.body.appendChild(form);
          window.open("", "view");
          form.submit();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.changeFormDomain();
      this.getUsersForSelectedDomain(newValue.domain);
    },
  },
};
</script>
